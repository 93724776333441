<template>
  <div>
    <PersonPortrait :title="title" :description="description" :image="image" />
    <PageFooter
      :author-name="authorName"
      :author-url="authorUrl"
      :dob="dob"
      :dod="dod"
    />
  </div>
</template>

<script setup>
import PersonPortrait from "./components/PersonPortrait.vue"
import PageFooter from "./components/PageFooter.vue"
import { ref } from "vue"

/**
 * Specify the name of the person you want to tribute.
 */
const title = ref("Harm Korte")

/**
 * Specify the description of the person you want to tribute.
 */
const description = ref(
  "Samen vormden wij een onbreekbare band. Papa, jouw vakmanschap en liefde maakten ons gezin compleet. Je leerde Duncan klussen, Nathalie vond troost in jouw knuffels. Jij en Mama, waren de lijm die ons bijeenhield. Jouw afwezigheid laat een onvervangbare leegte achter. In onze harten blijf je voortleven als onze gekozen familie. Voor altijd in liefde verbonden. 💔🌟"
)

/**
 * Specify the image of the person you want to tribute.
 *
 * @see https://stackoverflow.com/a/69896214/13765033
 */
const image = ref(require("./assets/harm-korte.jpg"))

/**
 * Specify the date of birth of the person you want to tribute.
 */
const dob = ref("12.03.1955")

/**
 * Specify the date of death of the person you want to tribute.
 */
const dod = ref("19.09.2023")

/**
 * Specify the name of the person you want to credit (your name).
 */
const authorName = ref("Duncan Heffron")

/**
 * Specify the URL of the person you want to credit (your website).
 */
const authorUrl = ref("https://github.com/duncanheffron")
</script>

<style lang="sass">
@import ./css/app

body
  min-height: 100vh
  background-color: black
  background-image: url(./assets/space.jpg)
  background-size: cover
  background-size: 100% 100%
  background-repeat: no-repeat
  background-position: center
  color: white
  font-family: 'mono'
  hyphens: auto
  display: flex
  align-items: center
  animation-name: bg
  animation-delay: 0.5s
  animation-duration: 30s
  animation-iteration-count: infinite
  animation-timing-function: ease-in-out

#app
  margin-left: auto
  margin-right: auto
  max-width: 320px

  @media (min-width: 768px)
    max-width: 440px
</style>
